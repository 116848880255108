import es from 'date-fns/locale/es';
import React,{Component,Fragment} from 'react';
import {Trans,withTranslation} from 'react-i18next';
import {loadReCaptcha,ReCaptcha} from 'react-recaptcha-v3';
import {Link} from 'react-router-dom';
import {toast} from 'react-semantic-toasts';
import DatePicker,{registerLocale} from 'react-datepicker';
import {
    Button,
    Checkbox,
    Container,
    Dimmer,
    Divider,
    Dropdown,
    Form,
    Header,
    Input,
    Loader
} from 'semantic-ui-react';

import Countries_en from '../translations/countries.en';
import Countries_es from '../translations/countries.es';

import {
    CAPTCHA_PUBLIC,
    COUNTRIES_WITHOUT_FLAG,
    ACQUIRE_URL
} from '../config';
import {
    apiFetch
} from '../utils';

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

class Acquire extends Component{
    constructor(props){
        super(props);

        this.state={
            data:{
                surname:'',
                name:'',
                id:'',
                birthdate:null,
                year:0,
                month:0,
                date:0,
                country:'',
                phone:'',
                email:'',
                agree:false
            },
            loading:false,
            captcha:''
        };

        this.feature=this.props.match.params.feature;

        this.handleChange=this.handleChange.bind(this);
        this.handleDatePicker=this.handleDatePicker.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleCaptcha=this.handleCaptcha.bind(this);

        switch(this.props.i18n.language.substring(0,2)||'es'){
            case 'es':
                registerLocale('es',es);

                break;
            default:
                break;
        }
    }

    getCountries(){
        const {i18n}=this.props,
            language=i18n.language.substring(0,2)||'es',
            Countries={
                'en':Countries_en,
                'es':Countries_es
            }[language];

        return Countries
        .map((country)=>{
            return {
                key:country.code,
                value:country.code,
                flag:COUNTRIES_WITHOUT_FLAG
                .includes(country.code)?
                    null:country.code,
                text:country.label
            };
        });
    }

    componentDidMount(){
        loadReCaptcha(CAPTCHA_PUBLIC);
    }

    handleChange(event,data){
        if(data){
            if(data.name==='agree'){
                this.setState({
                    data:{
                        ...this.state.data,
                        [data.name]:data.checked
                    }
                });
            }else{
                this.setState({
                    data:{
                        ...this.state.data,
                        [data.name]:data.value
                    }
                });
            }
        }
    }

    handleDatePicker(date){
        this.setState({
            data:{
                ...this.state.data,
                birthdate:date,
                year:date?date.getFullYear():0,
                month:date?date.getMonth():0,
                date:date?date.getDate():0
            }
        });
    }

    handleCaptcha(token){
        this.setState({
            captcha:token
        });
    }

    handleSubmit(event){
        const {data}=this.state,
            {t}=this.props;

        this.setState({
            loading:true
        });

        event.preventDefault();

        apiFetch({
            url:ACQUIRE_URL,
            method:'POST',
            data:{
                surname:data.surname,
                name:data.name,
                id:data.id,
                email:data.email,
                year:data.year,
                month:data.month,
                date:data.date,
                country:data.country,
                phone:data.phone,
                feature:this.feature,
                agree:data.agree,
                captcha:this.state.captcha
            },
            success:(data)=>{
                this.setState({
                    loading:false
                });

                toast({
                    title:t('api.sent'),
                    description:t(data.message),
                    type:'success',
                    icon:'send',
                    animation:'fade down',
                    time:10000
                });
            },
            error:(error)=>{
                this.setState({
                    loading:false
                });

                toast({
                    title:t('api.failed'),
                    description:t(error.body.message),
                    type:'error',
                    icon:'warning sign',
                    animation:'fade up',
                    time:10000
                });
            }
        });
    }

    render(){
        const {
                data,
                loading
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <div id='acquire' className='separator'>&nbsp;</div>
                <Container
                    className='text'
                >
                    <h1>{t('adquire.title')}</h1>
                    <p>{t('adquire.content')}</p>

                    <Dimmer.Dimmable dimmed={loading}>
                        <Header as='h4' dividing>
                            {t('adquire.personal')}
                        </Header>

                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label htmlFor='name'>
                                        {t('adquire.name')}
                                    </label>
                                    <Input
                                        name='name'
                                        type='text'
                                        value={data.name}
                                        onChange={this.handleChange}
                                        icon='user'
                                        iconPosition='left'
                                        required
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label htmlFor='surname'>
                                        {t('adquire.surname')}
                                    </label>
                                    <Input
                                        name='surname'
                                        type='text'
                                        value={data.surname}
                                        onChange={this.handleChange}
                                        icon='user'
                                        iconPosition='left'
                                        required
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label htmlFor='id'>
                                        {t('adquire.id')}
                                    </label>
                                    <Input
                                        name='id'
                                        type='text'
                                        value={data.id}
                                        onChange={this.handleChange}
                                        icon='id card'
                                        iconPosition='left'
                                        required
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label htmlFor='birthdate'>
                                        {t('adquire.birthdate')}
                                    </label>
                                    <DatePicker
                                        name='birthdate'
                                        selected={data.birthdate}
                                        dateFormat='yyyy-MM-dd'
                                        locale='es'
                                        onChange={this.handleDatePicker}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                    />
                                </Form.Field>
                            </Form.Group>

                            <Header as='h4' dividing>
                                {t('adquire.contact')}
                            </Header>

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label htmlFor='country'>
                                        {t('adquire.country')}
                                    </label>
                                    <Dropdown
                                        placeholder=
                                            {t('adquire.select_country')}
                                        fluid
                                        search
                                        selection
                                        name='country'
                                        options={this.getCountries()}
                                        value={data.country}
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label htmlFor='phone'>
                                        {t('adquire.phone')}
                                    </label>
                                    <Input
                                        name='phone'
                                        type='text'
                                        value={data.phone}
                                        onChange={this.handleChange}
                                        icon='phone'
                                        iconPosition='left'
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label htmlFor='email'>
                                        {t('adquire.email')}
                                    </label>
                                    <Input
                                        name='email'
                                        type='email'
                                        value={data.email||''}
                                        onChange={this.handleChange}
                                        icon='mail'
                                        iconPosition='left'
                                        required
                                    />
                                </Form.Field>
                                <Form.Field>
                                </Form.Field>
                            </Form.Group>
                            <Divider />
                            <Form.Group>
                                <Form.Field>
                                    <p>
                                        <Checkbox
                                            as='span'
                                            name='agree'
                                            checked={data.agree}
                                            onChange={this.handleChange}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <Trans
                                            i18nKey='adquire.accept'
                                            t={t}
                                        >
                                            I have read and accepted the
                                            <Link to='/terms'>Terms and
                                            conditions of use</Link> of the
                                            services and I am aware of the
                                            <Link to='/policy'>Privacy
                                            Policies</Link>.
                                        </Trans>
                                    </p>
                                </Form.Field>
                            </Form.Group>
                            <br />
                            <Button
                                floated='right'
                                type='submit'
                            >
                                {t('adquire.submit')}
                            </Button>
                        </Form>

                        <ReCaptcha
                            sitekey={CAPTCHA_PUBLIC}
                            action='submit'
                            verifyCallback={this.handleCaptcha}
                        />

                        <Dimmer inverted active={loading}>
                            <Loader
                                active={loading}
                                indeterminate>
                                {t('api.sending')}
                            </Loader>
                        </Dimmer>
                    </Dimmer.Dimmable>
                </Container>
            </Fragment>
        );
    }
}

export default withTranslation('global')(Acquire);


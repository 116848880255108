import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    Container,
    Divider,
    Image,
    Grid,
    List
} from 'semantic-ui-react';

import './Footer.css';

import Footer from '../resources/svg/footer.svg';

export default ()=>{
    const [t]=useTranslation('global');

    return (
        <footer id='footer'>
            <Container>
                <Divider />
                <br />
                <Grid stackable container width={3}>
                    <Grid.Column
                        only='computer'
                        width={8}
                    >
                        <Image src={Footer} alt='' />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <List>
                            <List.Item>
                                <List.Icon name='phone' />
                                <List.Content>
                                    <span>+507 3873850</span>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='mail' />
                                <List.Content>
                                    <a href='mailto:info@firmanza.com'>
                                        info@firmanza.com
                                    </a>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <List>
                            <List.Item
                                as='a'
                                href='/#about'
                            >
                                {t('footer.us')}
                            </List.Item>
                            <List.Item
                                as='a'
                                href='/privacy'
                            >
                                {t('footer.policy')}
                            </List.Item>
                            <List.Item
                                as='a'
                                href='/terms'
                            >
                                {t('footer.terms')}
                            </List.Item>
                            <List.Item
                                as='a'
                                href='/#contact'
                            >
                                {t('footer.contact')}
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
            </Container>
        </footer>
    );
};


export const COUNTRIES_WITHOUT_FLAG=[
    'aq','bl','bq','cw','gg','je','im','mf','ss','sx','xk'
];

export const CAPTCHA_PUBLIC='6LetY6YZAAAAAGkEdNKfxTwina8_uZek4G5HtT3s';
export const GOOGLE_ANALYTICS='UA-167851229-1';

export const SIGNIN_URL=process.env.NODE_ENV==='production'?
    'https://user.firmanza.com':'http://localhost:4001';
export const CONTACT_URL=process.env.NODE_ENV==='production'?
    'https://user.firmanza.com/api/contact':'http://localhost:4001/api/contact';
export const ACQUIRE_URL=process.env.NODE_ENV==='production'?
    'https://user.firmanza.com/api/acquire':'http://localhost:4001/api/acquire';

export const RESPONSIVE_TRIGGER=700;


import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
    Button,
    Container,
    Divider,
    Item
} from 'semantic-ui-react';

import SignMD_en from '../translations/sign.en.md';
import SignMD_es from '../translations/sign.es.md';
import WitnessMD_en from '../translations/witness.en.md';
import WitnessMD_es from '../translations/witness.es.md';
import EmailMD_en from '../translations/email.en.md';
import EmailMD_es from '../translations/email.es.md';
import SealMD_en from '../translations/seal.en.md';
import SealMD_es from '../translations/seal.es.md';

import './index.css';

class Features extends Component{
    constructor(props){
        super(props);

        this.state={
            list:[],
            markdown:{}
        };
    }

    componentDidMount(){
        const {i18n}=this.props,
            language=i18n.language.substring(0,2)||'es',
            SignMD={
                'en':SignMD_en,
                'es':SignMD_es
            }[language],
            WitnessMD={
                'en':WitnessMD_en,
                'es':WitnessMD_es
            }[language],
            EmailMD={
                'en':EmailMD_en,
                'es':EmailMD_es
            }[language],
            SealMD={
                'en':SealMD_en,
                'es':SealMD_es
            }[language];

        fetch('/resources/json/features.json',{
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        })
        .then(response=>response.json())
        .then((list)=>{
            this.setState({
                list:list
            });

            list
            .filter(i=>i.markdown)
            .map(i=>i.id)
            .forEach((feature)=>{
                fetch({
                    sign:SignMD,
                    witness:WitnessMD,
                    email:EmailMD,
                    seal:SealMD
                }[feature])
                .then(response=>response.text())
                .then((text)=>{
                    this.setState({
                        markdown:{
                            ...this.state.markdown,
                            [feature]:text
                        }
                    });
                });
            });
        });
    }

    render(){
        const {
                list,
                markdown
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <div id='features' className='separator'>&nbsp;</div>
                <Container
                    className='text'
                >
                    <h1>
                        {t('features.title')}
                    </h1>
                    <Item.Group relaxed>
                        {list
                        .filter((feature)=>{
                            return feature.markdown;
                        })
                        .map((feature,i)=>{
                            return (
                                <Fragment
                                    key={i}
                                >
                                    <Item
                                        id={'feature-'+feature.id}
                                    >
                                        <Item.Image
                                            size='tiny'
                                            src={feature.image}
                                        />
                                        <Item.Content>
                                            <Item.Header>
                                                <h2>
                                                    {t(feature.label)}
                                                </h2>
                                            </Item.Header>
                                            <ReactMarkdown
                                                children={markdown[feature.id]}
                                            />
                                            <Item.Extra>
                                                <Button
                                                    href={'/acquire/'+
                                                        feature.id}
                                                    floated='right'
                                                >
                                                    {t('features.adquire')}
                                                </Button>
                                            </Item.Extra>
                                        </Item.Content>
                                    </Item>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                    </Item.Group>
                </Container>
            </Fragment>
        );
    }
}

export default withTranslation('global')(Features);


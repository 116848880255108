import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
    Container
} from 'semantic-ui-react';

import TermsMD_en from '../translations/terms.en.md';
import TermsMD_es from '../translations/terms.es.md';

import './index.css';

class Terms extends Component{
    constructor(props){
        super(props);

        this.state={
            terms:''
        };
    }

    componentDidMount(){
        const {i18n}=this.props,
            language=i18n.language.substring(0,2)||'es',
            TermsMD={
                'en':TermsMD_en,
                'es':TermsMD_es
            }[language];

        fetch(TermsMD)
        .then(response=>response.text())
        .then(text=>{
            this.setState({
                terms:text
            });
        });
    }

    render(){
        const {terms}=this.state;

        return (
            <Fragment>
                <div id='terms' className='separator'>&nbsp;</div>
                <Container
                    className='text'
                >
                    <ReactMarkdown children={terms} />
                </Container>
            </Fragment>
        );
    }
}

export default withTranslation('global')(Terms);


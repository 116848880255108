import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    Segment
} from 'semantic-ui-react';

import DesktopMD_en from '../translations/desktop.en.md';
import DesktopMD_es from '../translations/desktop.es.md';

class Desktop extends Component{
    constructor(props){
        super(props);

        this.urls={
            panama:{
                homepage:'http://www.pki.gob.pa/drivers.htm',
                base:'http://www.pki.gob.pa/drivers'
            },
            opensc:{
                homepage:'https://github.com/OpenSC/OpenSC/wiki',
                base:'https://github.com/OpenSC/OpenSC/releases/download'
            },
            firmanza:{
                base:'https://www.firmanza.com/desktop'
            }
        };

        this.urls.panama.win64=this.urls.panama.base+
            '/SafeSignIC30112-x64-win-tu-admin.exe';
        this.urls.panama.win32=this.urls.panama.base+
            '/SafeSign-Identity-Client-3.0.45-admin.exe';
        this.urls.panama.mac=this.urls.panama.base+
            '/SafeSign_Identity_Client-Standard-3.0.72-general-i386-x86_64.zip';

        this.urls.opensc.win64=this.urls.opensc.base+
            '/0.21.0/OpenSC-0.21.0_win64.msi';
        this.urls.opensc.win32=this.urls.opensc.base+
            '/0.21.0/OpenSC-0.21.0_win32.msi';
        this.urls.opensc.mac=this.urls.opensc.base+
            '/0.21.0/OpenSC-0.21.0.dmg';

        this.state={
            title:'',
            list:[],
            original:'',
            desktop:''
        };
    }

    componentDidMount(){
        const url=this.urls,
            {i18n}=this.props,
            language=i18n.language.substring(0,2)||'es',
            DesktopMD={
                'en':DesktopMD_en,
                'es':DesktopMD_es
            }[language];

        let original='';

        fetch(DesktopMD)
        .then(response=>response.text())
        .then(text=>{
            original=text;

            return Promise.resolve();
        })
        .then(()=>{
            return fetch('/resources/json/signer.json',{
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                }
            });
        })
        .then(response=>response.json())
        .then((json)=>{
            this.setState({
                title:json.title,
                list:json.list,
                original:original,
                desktop:original
                .replaceAll('{{title}}',json.title)
                .replaceAll('{{panama_homepage}}',url.panama.homepage)
                .replaceAll('{{panama_win64}}',url.panama.win64)
                .replaceAll('{{panama_win32}}',url.panama.win32)
                .replaceAll('{{panama_mac}}',url.panama.mac)
                .replaceAll('{{opensc_homepage}}',url.opensc.homepage)
                .replaceAll('{{opensc_win64}}',url.opensc.win64)
                .replaceAll('{{opensc_win32}}',url.opensc.win32)
                .replaceAll('{{opensc_mac}}',url.opensc.mac)
            });
        });
    }

    render(){
        const firmanza_base=this.urls.firmanza.base,
            {
                list,
                desktop
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <div id='desktop' className='separator'>&nbsp;</div>
                <Container
                    className='text'
                >
                    <h1>
                        {t('desktop.title')}
                    </h1>
                    <ReactMarkdown children={desktop.toString()} />

                    <Grid divided padded>
                        {list.map((item,i)=>(
                            <Grid.Column key={'signer-'+i} width={8}>
                                <Segment placeholder>
                                    <Header icon>
                                        <Icon
                                            className='brown'
                                            name={list[i].icon}
                                        />
                                        <div className='brown'>
                                            {list[i].title}
                                        </div>
                                        <Header.Subheader>
                                            MD5: {list[i].checksum}
                                        </Header.Subheader>
                                    </Header>
                                    <Button href={firmanza_base+list[i].file}>
                                        {t('desktop.submit')}
                                    </Button>
                                </Segment>
                            </Grid.Column>
                        ))}
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}

export default withTranslation('global')(Desktop);


import AOS from 'aos';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React,{Fragment} from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {I18nextProvider} from 'react-i18next';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import {Provider} from 'react-redux';
import {SemanticToastContainer} from 'react-semantic-toasts';
import {createStore} from 'redux';
import {Sidebar} from 'semantic-ui-react';

import {
    GOOGLE_ANALYTICS
} from './config';
import {
    Footer,
    Menubar,
    Offcanvas
} from './components';
import {
    About,
    Acquire,
    Contact,
    Desktop,
    Features,
    Headline,
    NotFound,
    Privacy,
    Terms
} from './containers';

import allReducers from './reducers';
import * as serviceWorker from './serviceWorker';

import 'aos/dist/aos.css';
import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './index.css';

import en from './translations/strings.en.json';
import es from './translations/strings.es.json';

const store=createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__&&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);

AOS.init();

ReactGA.initialize(GOOGLE_ANALYTICS);
ReactGA.pageview(window.location.pathname+window.location.search);

i18next
.use(LanguageDetector)
.init({
    lng:localStorage.getItem('lang')||'es',
    fallbackLng:'es',
    debug:false,
    resources:{
        es:{
            global:es
        },
        en:{
            global:en
        }
    },
    detection:{
        order:['localStorage','navigator'],
        lookupQuerystring:'lng',
        lookupLocalStorage:'lang',
        caches:['localStorage']
    },
    interpolation:{
        escapeValue:false
    }
});

document.getElementById('root')&&
    ReactDOM.render(
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <Router>
                    <Menubar />
                    <Sidebar.Pushable>
                        <Offcanvas />
                        <Sidebar.Pusher>
                            <Switch>
                                <Route exact
                                    path='/'
                                    render={(props)=>
                                        <Fragment>
                                            <Headline />
                                            <About />
                                            <Features />
                                            <Contact />
                                        </Fragment>
                                    }
                                />

                                <Route exact
                                    path='/privacy'
                                    component={Privacy}
                                />

                                <Route exact
                                    path='/terms'
                                    component={Terms}
                                />

                                <Route exact
                                    path='/desktop'
                                    component={Desktop}
                                />

                                <Route exact
                                    path='/acquire/:feature'
                                    component={Acquire}
                                />

                                <Route
                                    status={404}
                                    component={NotFound}
                                />
                            </Switch>
                            <Footer />
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                    <SemanticToastContainer
                        position='bottom-center'
                        className='snackbar'
                    />
                </Router>
            </I18nextProvider>
        </Provider>,
        document.getElementById('root')
    );

serviceWorker.unregister();


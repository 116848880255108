import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
    Menu,
    Sidebar
} from 'semantic-ui-react';

import {
    SIGNIN_URL
} from '../config';

export default ()=>{
    const offcanvas=useSelector(state=>state.offcanvas),
        [t]=useTranslation('global');

    return (
        <Sidebar
            id='sidebar'
            as={Menu}
            icon='labeled'
            inverted
            animation='slide along'
            vertical
            visible={offcanvas}
            width='thin'
        >
            <Menu.Item
                as='a'
                href='/'
            >
                {t('menubar.home')}
            </Menu.Item>
            <Menu.Item
                as='a'
                href='/#about'
            >
                {t('menubar.us')}
            </Menu.Item>
            <Menu.Item
                as='a'
                href='/#features'
            >
                {t('menubar.features')}
            </Menu.Item>
            <Menu.Item
                as='a'
                href='/desktop'
            >
                {t('menubar.downloads')}
            </Menu.Item>
            <Menu.Item
                as='a'
                href='/#contact'
            >
                {t('menubar.contact')}
            </Menu.Item>

            <Menu.Item
                as='a'
                href={SIGNIN_URL}
            >
                {t('menubar.login')}
            </Menu.Item>
        </Sidebar>
    );
};


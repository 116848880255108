import {
    OFFCANVAS_HIDDEN,
    OFFCANVAS_VISIBLE
} from '../constants';

const initialState=false;

const offcanvas=(state,action)=>{
    switch(action.type){
        case OFFCANVAS_VISIBLE:
            return true;
        case OFFCANVAS_HIDDEN:
            return false;
        default:
            return initialState;
    }
};

export default offcanvas;


import {createMedia} from '@artsy/fresnel';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch,useSelector} from 'react-redux';
import {
    Button,
    Flag,
    Icon,
    Menu
} from 'semantic-ui-react';

import {
    RESPONSIVE_TRIGGER,
    SIGNIN_URL
} from '../config';
import {
    OFFCANVAS_HIDDEN,
    OFFCANVAS_VISIBLE
} from '../constants';

const {
        MediaContextProvider,
        Media
    }=createMedia({
        breakpoints:{
            sm:0,
            md:RESPONSIVE_TRIGGER
        }
    }),
    hide=()=>{
        return {
            type:OFFCANVAS_HIDDEN
        };
    },
    show=()=>{
        return {
            type:OFFCANVAS_VISIBLE
        };
    };

export default ()=>{
    const offcanvas=useSelector(state=>state.offcanvas),
        dispatch=useDispatch(),
        [t,i18n]=useTranslation('global');

    return (
        <MediaContextProvider>
            <Menu
                id='menubar'
                inverted
            >
                <Media at='sm'>
                    <Menu.Item
                        id='bars'
                        as='a'
                        onClick={()=>dispatch(offcanvas?hide():show())}
                    >
                        <Icon name='bars' />
                    </Menu.Item>
                </Media>

                <Media greaterThanOrEqual='md'>
                    <Menu.Item
                        as='a'
                        href='/'
                    >
                        {t('menubar.home')}
                    </Menu.Item>
                </Media>
                <Media greaterThanOrEqual='md'>
                    <Menu.Item
                        as='a'
                        href='/#about'>
                        {t('menubar.us')}
                    </Menu.Item>
                </Media>
                <Media greaterThanOrEqual='md'>
                    <Menu.Item
                        as='a'
                        href='/#features'>
                        {t('menubar.features')}
                    </Menu.Item>
                </Media>
                <Media greaterThanOrEqual='md'>
                    <Menu.Item
                        as='a'
                        href='/desktop'>
                        {t('menubar.downloads')}
                    </Menu.Item>
                </Media>
                <Media greaterThanOrEqual='md'>
                    <Menu.Item
                        as='a'
                        href='/#contact'>
                        {t('menubar.contact')}
                    </Menu.Item>
                </Media>

                <Menu.Menu position='right'>
                    <Button.Group>
                        <Button
                            className='lang'
                            floated='left'
                            size='medium'
                            onClick={
                                ()=>{
                                    i18n.changeLanguage('es');

                                    window.location
                                    .reload(false);
                                }
                            }
                            compact
                            basic
                        >
                            <Flag name='pa' />
                        </Button>
                        <Button
                            className='lang'
                            floated='left'
                            size='medium'
                            onClick={
                                ()=>{
                                    i18n.changeLanguage('en');

                                    window.location
                                    .reload(false);
                                }
                            }
                            compact
                            basic
                        >
                            <Flag name='us' />
                        </Button>
                    </Button.Group>

                    <Menu.Item
                        as='a'
                        href={SIGNIN_URL}
                        className='right item'
                    >
                        {t('menubar.login')}
                    </Menu.Item>
                </Menu.Menu>

            </Menu>
        </MediaContextProvider>
    );
};


function apiFetch({
    url,
    method,
    data,
    success,
    error=()=>{},
    t=((s)=>s)
}){
    let status;

    return fetch(url,{
        method:method,
        credentials:'same-origin',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(data)
    })
    .then((response)=>{
        status=response.status;

        return response.json();
    })
    .then((body)=>{
        if(status===200||status===201){
            return success(body);
        }else{
            return error({
                status:status,
                body:body
            });
        }
    })
    .catch((e)=>{
        console.log(e);

        return error({
            status:0,
            body:{
                ok:false,
                message:t('api.error')
            }
        });
    });
}

export {
    apiFetch
};


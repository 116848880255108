import React,{Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
    Container,
    Segment,
    Statistic
} from 'semantic-ui-react';

import './index.css';
 
class NotFound extends Component{
    render(){
        const {t}=this.props;

        return (
            <Container
                className='text'
            >
                <div id='notfound' className='separator'>&nbsp;</div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Segment placeholder>
                    <Statistic color='red'>
                        <Statistic.Value>404</Statistic.Value>
                        <Statistic.Label>
                            {t('api.notfound')}
                        </Statistic.Label>
                    </Statistic>
                </Segment>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Container>
        );
    }
}

export default withTranslation('global')(NotFound);


import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {
    Container
} from 'semantic-ui-react';

import './Headline.css';

import Central from '../resources/svg/central.svg';
 
class Headline extends Component{
    constructor(props){
        super(props);

        this.state={
            list:[]
        };
    }

    componentDidMount(){
        fetch('/resources/json/features.json',{
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        })
        .then(response=>response.json())
        .then((json)=>{
            this.setState({
                list:json
            });
        });
    }

    render(){
        const {
                list
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <Container id='slider'>
                    <div className='background'>
                        <div className='card'></div>
                        <div className='card'></div>
                        <div className='card'></div>
                    </div>
                    <div
                        className='logo'
                        data-aos='fade-up'
                        data-aos-duration='3000'
                    >
                        <img src={Central} alt='' />
                    </div>
                </Container>
                <Container id='list'>
                    {list
                    .filter((feature)=>{
                        return feature.show;
                    })
                    .map((feature,i)=>{
                        return (
                            <a
                                key={feature.id}
                                href={'/#feature-'+feature.id}
                                data-aos='fade-down'
                                data-aos-duration='3000'
                                data-aos-delay={500*i}
                            >
                                <img
                                    src={feature.image}
                                    alt=''
                                />
                                <p>{t(feature.label)}</p>
                            </a>
                        );
                    })}
                </Container>
            </Fragment>
        );
    }
}

export default withTranslation('global')(withRouter(Headline));


import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
    Container
} from 'semantic-ui-react';

import AboutMD_en from '../translations/about.en.md';
import AboutMD_es from '../translations/about.es.md';

import './index.css';

class About extends Component{
    constructor(props){
        super(props);

        this.state={
            about:''
        };
    }

    componentDidMount(){
        const {i18n}=this.props,
            language=i18n.language.substring(0,2)||'es';

        let AboutMD;

        switch(language){
            case 'en':
                AboutMD=AboutMD_en;

                break;
            case 'es':
                AboutMD=AboutMD_es;

                break;
            default:
                AboutMD=AboutMD_es;
        }

        fetch(AboutMD)
        .then(response=>response.text())
        .then(text=>{
            this.setState({
                about:text
            });
        });
    }

    render(){
        const {about}=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <div id='about' className='separator'>&nbsp;</div>
                <Container
                    className='text'
                >
                    <h1>{t('us.title')}</h1>
                    <ReactMarkdown children={about} />
                </Container>
            </Fragment>
        );
    }
}

export default withTranslation('global')(About);


import React,{Component,Fragment} from 'react';
import {loadReCaptcha,ReCaptcha} from 'react-recaptcha-v3';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-semantic-toasts';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Input,
    Loader,
    TextArea
} from 'semantic-ui-react';

import {
    CAPTCHA_PUBLIC,
    CONTACT_URL
} from '../config';
import {
    apiFetch
} from '../utils';

import './index.css';

class Contact extends Component{
    constructor(props){
        super(props);

        this.state={
            data:{
                fullname:'',
                email:'',
                enterprise:'',
                phone:'',
                subject:''
            },
            loading:false,
            captcha:''
        };

        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleCaptcha=this.handleCaptcha.bind(this);
    }

    componentDidMount(){
        loadReCaptcha(CAPTCHA_PUBLIC);
    }

    handleChange(event,data){
        this.setState({
            data:{
                ...this.state.data,
                [data.name]:data.value
            }
        });
    }

    handleCaptcha(token){
        this.setState({
            captcha:token
        });
    }

    handleSubmit(event){
        const {data}=this.state,
            {t}=this.props;

        this.setState({
            loading:true
        });

        event.preventDefault();

        apiFetch({
            url:CONTACT_URL,
            method:'POST',
            data:{
                fullname:data.fullname,
                email:data.email,
                enterprise:data.enterprise,
                phone:data.phone,
                subject:data.subject,
                captcha:this.state.captcha
            },
            success:(data)=>{
                this.setState({
                    loading:false
                });

                toast({
                    title:t('api.sent'),
                    description:t(data.message),
                    type:'success',
                    icon:'send',
                    animation:'fade down',
                    time:10000
                });
            },
            error:(error)=>{
                this.setState({
                    loading:false
                });

                toast({
                    title:t('api.failed'),
                    description:t(error.body.message),
                    type:'error',
                    icon:'warning sign',
                    animation:'fade up',
                    time:10000
                });
            }
        });
    }

    render(){
        const {
                data,
                loading
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <div id='contact' className='separator'>&nbsp;</div>
                <Container
                    className='text'
                >
                    <h1>{t('contact.title')}</h1>
                    <Dimmer.Dimmable dimmed={loading}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Field required>
                                <label htmlFor='fullname'>
                                    {t('contact.fullname')}
                                </label>
                                <Input
                                    name='fullname'
                                    type='text'
                                    value={data.fullname}
                                    onChange={this.handleChange}
                                    icon='user'
                                    iconPosition='left'
                                    required
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label htmlFor='email'>
                                    {t('contact.email')}
                                </label>
                                <Input
                                    name='email'
                                    type='text'
                                    value={data.email}
                                    onChange={this.handleChange}
                                    icon='mail'
                                    iconPosition='left'
                                    required
                                />
                            </Form.Field>
                            <Form.Field>
                                <label htmlFor='enterprise'>
                                    {t('contact.enterprise')}
                                </label>
                                <Input
                                    name='enterprise'
                                    type='text'
                                    value={data.enterprise}
                                    onChange={this.handleChange}
                                    icon='building'
                                    iconPosition='left'
                                />
                            </Form.Field>
                            <Form.Field>
                                <label htmlFor='phone'>
                                    {t('contact.phone')}
                                </label>
                                <Input
                                    name='phone'
                                    type='text'
                                    value={data.phone}
                                    onChange={this.handleChange}
                                    icon='phone'
                                    iconPosition='left'
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label htmlFor='subject'>
                                    {t('contact.message')}
                                </label>
                                <TextArea
                                    name='subject'
                                    rows={10}
                                    value={data.subject}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <br />
                            <Button
                                floated='right'
                                type='submit'
                            >
                                {t('contact.submit')}
                            </Button>
                        </Form>

                        <ReCaptcha
                            sitekey={CAPTCHA_PUBLIC}
                            action='submit'
                            verifyCallback={this.handleCaptcha}
                        />

                        <Dimmer inverted active={loading}>
                            <Loader
                                active={loading}
                                indeterminate>
                                {t('api.sending')}
                            </Loader>
                        </Dimmer>
                    </Dimmer.Dimmable>
                </Container>
            </Fragment>
        );
    }
}

export default withTranslation('global')(Contact);

